<template>
  <chastia-device-table-base v-bind="$attrs" :filters="_filters" @clear="clearFilters">
    <template slot="filters"></template>
  </chastia-device-table-base>
</template>

<script>
import ChastiaDeviceTableBase from './ChastiaDeviceTableBase';

export default {
  name: 'chastia-device-table',
  components: {
    ChastiaDeviceTableBase,
  },
  props: {
    filters: {
      type: Object,
      default: () => ({}),
    },
  },
  computed: {
    _filters() {
      const filters = {};

      return Object.assign({}, filters, this.filters); // Props will always override filters
    },
  },
  methods: {
    clearFilters() {
      // Clear the filters
    },
  },
  data() {
    return {
      // Filters
    };
  },
};
</script>
