import connector from './connector';

/**
 * A singleton used to manage the user session
 */
class Session {
  /** The key of jwt in the local storage */
  static LOCAL_STORAGE_KEY_JWT = 'phillip.session.jwt';

  /** The key of email in the local storage */
  static LOCAL_STORAGE_KEY_EMAIL = 'phillip.session.email';

  /** The key of name in the local storage */
  static LOCAL_STORAGE_KEY_NAME = 'phillip.session.name';

  /** The key of id in the local storage */
  static LOCAL_STORAGE_KEY_ID = 'phillip.session.id';

  /**
   * Wipes the saved session from the local storage
   */
  _wipeLocal() {
    window.localStorage.removeItem(Session.LOCAL_STORAGE_KEY_JWT);
    window.localStorage.removeItem(Session.LOCAL_STORAGE_KEY_EMAIL);
    window.localStorage.removeItem(Session.LOCAL_STORAGE_KEY_NAME);
    window.localStorage.removeItem(Session.LOCAL_STORAGE_KEY_ID);
  }

  /**
   * Saves the session to local storage
   */
  _saveToLocal() {
    window.localStorage.setItem(Session.LOCAL_STORAGE_KEY_JWT, this.jwt);
    window.localStorage.setItem(Session.LOCAL_STORAGE_KEY_EMAIL, this.email);
    window.localStorage.setItem(Session.LOCAL_STORAGE_KEY_NAME, this.name);
    window.localStorage.setItem(Session.LOCAL_STORAGE_KEY_ID, this.id);
  }

  /**
   * Loads the saved session from local storage
   */
  _loadFromLocal() {
    this.jwt = window.localStorage.getItem(Session.LOCAL_STORAGE_KEY_JWT);
    this.email = window.localStorage.getItem(Session.LOCAL_STORAGE_KEY_EMAIL);
    this.name = window.localStorage.getItem(Session.LOCAL_STORAGE_KEY_NAME);
    this.id = window.localStorage.getItem(Session.LOCAL_STORAGE_KEY_ID);
  }

  /**
   * Logs in user with the specified credentials
   *
   * @param {string} email
   * @param {string} password
   *
   * @returns {Promise<void>}
   */
  async login(email, password) {
    const { data } = await connector.post('login', { email, password, platform: 'web' });

    if (data.user.role !== 'admin') {
      throw new Error('Unauthorized access');
    }

    this.jwt = data.token;
    this.name = data.user.fullName;
    this.id = data.user.id;
    this.email = email;

    this._saveToLocal();
  }

  /**
   * Logouts the currently logged-in user
   */
  logout() {
    this.jwt = null;
    this.email = null;
    this.name = null;
    this.id = null;

    this._wipeLocal();
  }

  /**
   * Whether the logged in user is permitted to access debug features.
   */
  get debug() {
    return this.email === 'phillip@creanet.sk';
  }

  /**
   * Creates a new instance of Session
   */
  constructor() {
    /**
     * The JWT token
     *
     * @type {string}
     */
    this.jwt = null;

    /**
     * The email of the currently logged in user
     *
     * @type {string}
     */
    this.email = null;

    /**
     * The full name of the currently logged in user
     *
     * @type {string}
     */
    this.name = null;

    /**
     * The id of the currently logged in user
     *
     * @type {number}
     */
    this.id = null;

    this._loadFromLocal();
  }
}

const session = new Session();

export default session;
